import React, { useEffect, useState } from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import ReactPlayer from 'react-player/lazy';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import Fab from '@material-ui/core/Fab';
import Lottie from 'react-lottie';
import musicAnimation from './music-lottie';
import { useSelector } from 'react-redux';
import { updateAudioState } from '../../store/actions/actionsMain';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  dotsClass: 'slick-dots slick-thumb',

  appendDots: (dots) => (
    <div
      style={{
        marginBottom: 34,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        height: 20,
        alignItems: 'flex-start',
      }}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
        }}
        style={{ cursor: 'default' }}
      >
        <ul className="discover-dots-ul"> {dots} </ul>
      </div>
    </div>
  ),
  customPaging: (i) => <div className="dots-discover"></div>,
};

export default function AudioComponent(props) {
  const audioStatusRedux = useSelector(
    (state) => state.audioReducer.audioStatus,
  );
  const dispatch = useDispatch();
  const [audioCovers, setaudioCovers] = useState([]);

  useEffect(() => {
    return dispatch(updateAudioState(null));
  }, []);

  useEffect(() => {
    if (props.nftData && props.nftData.audioCoverFile250) {
      setaudioCovers(
        Array.isArray(props.nftData.audioCoverFile250)
          ? props.nftData.audioCoverFile250
          : [props.nftData.audioCoverFile250],
      );
    }
  }, [props.nftData]);

  return (
    <div style={{ display: 'block' }} className="link-flex-res">
      <Slider {...settings}>
        {audioCovers &&
          audioCovers.map((ele, index) => {
            return (
              <div key={'nftcard_music_comp' + index}>
                <AspectRatio
                  ratio="1/1"
                  style={{
                    width: '100%',
                    borderRadius: '18px',
                  }}
                >
                  <div className="flex w-full h-full">
                    <AspectRatio
                      ratio="1/1"
                      style={{
                        width: '100%',
                        borderRadius: '18px',
                        position: 'relative',
                      }}
                    >
                      {' '}
                      <div
                        style={{ borderRadius: '18px' }}
                        className="skeleton-moon  "
                      />
                    </AspectRatio>

                    <img
                      key={ele + 'imgNFTT'}
                      src={ele ? ele : '/img/cover/cover_260x260.jpg'}
                      placeholder="/img/cover/cover_260x260.jpg"
                      className="top-0 left-0 w-full h-full object-cover"
                      width="100%"
                      height="100%"
                      style={{
                        position: 'absolute',
                        borderRadius: '18px',
                      }}
                    />
                  </div>
                </AspectRatio>
              </div>
            );
          })}
      </Slider>

      <div
        style={{
          position: 'absolute',
          bottom: 0,
          height: '100%',
        }}
      >
        {audioStatusRedux &&
          audioStatusRedux?.index === props.index + 1 &&
          audioStatusRedux?.playingCategory === props.category && (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: musicAnimation,
              }}
              speed={1}
              isClickToPauseDisabled={true}
              style={{
                margin: 'auto',
                width: '100%',
                position: 'absolute',
                zIndex: 500,
              }}
            />
          )}
        <div className="absolute" style={{ bottom: 0, right: 0 }}>
          <div
            className="absolute ml-auto w-full flex justify-center items-center blue_color btn_overwrite_background"
            style={{
              width: 60,
              height: 60,
              bottom: 0,
              right: 0,
              zIndex: 501,
              marginRight: 0,
            }}
          >
            <Fab
              color="secondary"
              style={{
                background: 'transparent',
                boxShadow: 'none',
              }}
              className="btn-color"
              onClick={(e) => {
                e.stopPropagation();

                if (
                  audioStatusRedux &&
                  audioStatusRedux?.index === props.index + 1
                ) {
                  dispatch(updateAudioState({}));
                } else {
                  dispatch(
                    updateAudioState({
                      index: props.index + 1,
                      playingCategory: props.category,
                    }),
                  );
                }
              }}
            >
              {audioStatusRedux &&
              audioStatusRedux?.index === props.index + 1 &&
              audioStatusRedux?.playingCategory === props.category ? (
                <PauseCircleIcon fontSize="large" />
              ) : (
                <PlayCircleIcon fontSize="large" />
              )}
            </Fab>
          </div>
        </div>
        <ReactPlayer
          playsinline={true}
          height="100%"
          width="100%"
          url={props.nftData.sampleAudioFile}
          controls={false}
          playing={
            audioStatusRedux &&
            audioStatusRedux?.index === props.index + 1 &&
            audioStatusRedux?.playingCategory === props.category
          }
          onEnded={() => {
            if (audioStatusRedux) {
              dispatch(updateAudioState({}));
            }
          }}
        />
      </div>
    </div>
  );
}
